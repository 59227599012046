import React from 'react';
import './scss/home.scss'
const Home = () => {
  return (
    <div className='container'>
      <div className='copyright'>
        ©Copyright2021-2022 FreeWeb. All Rights Reserved. 任网游版权所有 粤ICP备08122787号
      </div>
    </div>
  );
};

export default Home;